import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _09d6d1dc = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _27adb9b0 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "pages/AboutUs" */))
const _6e5f03f3 = () => interopDefault(import('../pages/Business.vue' /* webpackChunkName: "pages/Business" */))
const _8a346bd8 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _014b8b3a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _1ebd8af4 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _e15ee6de = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _63404cb3 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _4cce185a = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _23a4c6ed = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _17a9be34 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _a7399486 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _9ab36232 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1c6e1d56 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _5d03a850 = () => interopDefault(import('../modules/customer/pages/MyAccount/FastOrder.vue' /* webpackChunkName: "" */))
const _17fdde25 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyDownloads.vue' /* webpackChunkName: "" */))
const _76e866ef = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _469ff2de = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _5dbfdc56 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _5217217b = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6afee6d6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _794219e1 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _2b5a4d90 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _81f399ce = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _70eaa73e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _7e477c33 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _f12a585e = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _4b49acc2 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _30ae4956 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))
const _20ee94f8 = () => interopDefault(import('../pages/Storyblok.vue' /* webpackChunkName: "pages/Storyblok" */))
const _be8e9d8e = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de_et",
    component: _09d6d1dc,
    name: "home___de_et"
  }, {
    path: "/fr_et",
    component: _09d6d1dc,
    name: "home___fr_et"
  }, {
    path: "/nl_et",
    component: _09d6d1dc,
    name: "home___nl_et"
  }, {
    path: "/us_et",
    component: _09d6d1dc,
    name: "home___us_et"
  }, {
    path: "/de_et/AboutUs",
    component: _27adb9b0,
    name: "AboutUs___de_et"
  }, {
    path: "/de_et/Business",
    component: _6e5f03f3,
    name: "Business___de_et"
  }, {
    path: "/de_et/cart",
    component: _8a346bd8,
    name: "cart___de_et"
  }, {
    path: "/de_et/checkout",
    component: _014b8b3a,
    name: "checkout___de_et",
    children: [{
      path: "billing",
      component: _1ebd8af4,
      name: "billing___de_et"
    }, {
      path: "payment",
      component: _e15ee6de,
      name: "payment___de_et"
    }, {
      path: "shipping",
      component: _63404cb3,
      name: "shipping___de_et"
    }, {
      path: "thank-you",
      component: _4cce185a,
      name: "thank-you___de_et"
    }, {
      path: "user-account",
      component: _23a4c6ed,
      name: "user-account___de_et"
    }]
  }, {
    path: "/de_et/Cms",
    component: _17a9be34,
    name: "Cms___de_et"
  }, {
    path: "/de_et/Contact",
    component: _a7399486,
    name: "Contact___de_et"
  }, {
    path: "/de_et/customer",
    component: _9ab36232,
    meta: {"titleLabel":"My Account"},
    name: "customer___de_et",
    children: [{
      path: "addresses-details",
      component: _1c6e1d56,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___de_et"
    }, {
      path: "fast-order",
      component: _5d03a850,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___de_et"
    }, {
      path: "my-downloads",
      component: _17fdde25,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___de_et"
    }, {
      path: "my-newsletter",
      component: _76e866ef,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___de_et"
    }, {
      path: "my-profile",
      component: _469ff2de,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___de_et"
    }, {
      path: "my-reviews",
      component: _5dbfdc56,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___de_et"
    }, {
      path: "my-wishlist",
      component: _5217217b,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___de_et"
    }, {
      path: "order-history",
      component: _6afee6d6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___de_et"
    }, {
      path: "addresses-details/create",
      component: _794219e1,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___de_et"
    }, {
      path: "/de_et/reset-password",
      component: _2b5a4d90,
      alias: "/customer/account/createPassword",
      name: "reset-password___de_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _81f399ce,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___de_et"
    }, {
      path: "order-history/:orderId",
      component: _70eaa73e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___de_et"
    }]
  }, {
    path: "/de_et/Faq",
    component: _7e477c33,
    name: "Faq___de_et"
  }, {
    path: "/de_et/ForgotPassword",
    component: _f12a585e,
    name: "ForgotPassword___de_et"
  }, {
    path: "/de_et/Home",
    component: _09d6d1dc,
    name: "Home___de_et"
  }, {
    path: "/de_et/Page",
    component: _4b49acc2,
    name: "Page___de_et"
  }, {
    path: "/de_et/Register",
    component: _30ae4956,
    name: "Register___de_et"
  }, {
    path: "/de_et/Storyblok",
    component: _20ee94f8,
    name: "Storyblok___de_et"
  }, {
    path: "/de_et/thank-you",
    component: _be8e9d8e,
    name: "thank-you___de_et"
  }, {
    path: "/fr_et/AboutUs",
    component: _27adb9b0,
    name: "AboutUs___fr_et"
  }, {
    path: "/fr_et/Business",
    component: _6e5f03f3,
    name: "Business___fr_et"
  }, {
    path: "/fr_et/cart",
    component: _8a346bd8,
    name: "cart___fr_et"
  }, {
    path: "/fr_et/checkout",
    component: _014b8b3a,
    name: "checkout___fr_et",
    children: [{
      path: "billing",
      component: _1ebd8af4,
      name: "billing___fr_et"
    }, {
      path: "payment",
      component: _e15ee6de,
      name: "payment___fr_et"
    }, {
      path: "shipping",
      component: _63404cb3,
      name: "shipping___fr_et"
    }, {
      path: "thank-you",
      component: _4cce185a,
      name: "thank-you___fr_et"
    }, {
      path: "user-account",
      component: _23a4c6ed,
      name: "user-account___fr_et"
    }]
  }, {
    path: "/fr_et/Cms",
    component: _17a9be34,
    name: "Cms___fr_et"
  }, {
    path: "/fr_et/Contact",
    component: _a7399486,
    name: "Contact___fr_et"
  }, {
    path: "/fr_et/customer",
    component: _9ab36232,
    meta: {"titleLabel":"My Account"},
    name: "customer___fr_et",
    children: [{
      path: "addresses-details",
      component: _1c6e1d56,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___fr_et"
    }, {
      path: "fast-order",
      component: _5d03a850,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___fr_et"
    }, {
      path: "my-downloads",
      component: _17fdde25,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___fr_et"
    }, {
      path: "my-newsletter",
      component: _76e866ef,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___fr_et"
    }, {
      path: "my-profile",
      component: _469ff2de,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___fr_et"
    }, {
      path: "my-reviews",
      component: _5dbfdc56,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___fr_et"
    }, {
      path: "my-wishlist",
      component: _5217217b,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___fr_et"
    }, {
      path: "order-history",
      component: _6afee6d6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___fr_et"
    }, {
      path: "addresses-details/create",
      component: _794219e1,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___fr_et"
    }, {
      path: "/fr_et/reset-password",
      component: _2b5a4d90,
      alias: "/customer/account/createPassword",
      name: "reset-password___fr_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _81f399ce,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___fr_et"
    }, {
      path: "order-history/:orderId",
      component: _70eaa73e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___fr_et"
    }]
  }, {
    path: "/fr_et/Faq",
    component: _7e477c33,
    name: "Faq___fr_et"
  }, {
    path: "/fr_et/ForgotPassword",
    component: _f12a585e,
    name: "ForgotPassword___fr_et"
  }, {
    path: "/fr_et/Home",
    component: _09d6d1dc,
    name: "Home___fr_et"
  }, {
    path: "/fr_et/Page",
    component: _4b49acc2,
    name: "Page___fr_et"
  }, {
    path: "/fr_et/Register",
    component: _30ae4956,
    name: "Register___fr_et"
  }, {
    path: "/fr_et/Storyblok",
    component: _20ee94f8,
    name: "Storyblok___fr_et"
  }, {
    path: "/fr_et/thank-you",
    component: _be8e9d8e,
    name: "thank-you___fr_et"
  }, {
    path: "/nl_et/AboutUs",
    component: _27adb9b0,
    name: "AboutUs___nl_et"
  }, {
    path: "/nl_et/Business",
    component: _6e5f03f3,
    name: "Business___nl_et"
  }, {
    path: "/nl_et/cart",
    component: _8a346bd8,
    name: "cart___nl_et"
  }, {
    path: "/nl_et/checkout",
    component: _014b8b3a,
    name: "checkout___nl_et",
    children: [{
      path: "billing",
      component: _1ebd8af4,
      name: "billing___nl_et"
    }, {
      path: "payment",
      component: _e15ee6de,
      name: "payment___nl_et"
    }, {
      path: "shipping",
      component: _63404cb3,
      name: "shipping___nl_et"
    }, {
      path: "thank-you",
      component: _4cce185a,
      name: "thank-you___nl_et"
    }, {
      path: "user-account",
      component: _23a4c6ed,
      name: "user-account___nl_et"
    }]
  }, {
    path: "/nl_et/Cms",
    component: _17a9be34,
    name: "Cms___nl_et"
  }, {
    path: "/nl_et/Contact",
    component: _a7399486,
    name: "Contact___nl_et"
  }, {
    path: "/nl_et/customer",
    component: _9ab36232,
    meta: {"titleLabel":"My Account"},
    name: "customer___nl_et",
    children: [{
      path: "addresses-details",
      component: _1c6e1d56,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nl_et"
    }, {
      path: "fast-order",
      component: _5d03a850,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___nl_et"
    }, {
      path: "my-downloads",
      component: _17fdde25,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___nl_et"
    }, {
      path: "my-newsletter",
      component: _76e866ef,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___nl_et"
    }, {
      path: "my-profile",
      component: _469ff2de,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___nl_et"
    }, {
      path: "my-reviews",
      component: _5dbfdc56,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___nl_et"
    }, {
      path: "my-wishlist",
      component: _5217217b,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___nl_et"
    }, {
      path: "order-history",
      component: _6afee6d6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nl_et"
    }, {
      path: "addresses-details/create",
      component: _794219e1,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nl_et"
    }, {
      path: "/nl_et/reset-password",
      component: _2b5a4d90,
      alias: "/customer/account/createPassword",
      name: "reset-password___nl_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _81f399ce,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nl_et"
    }, {
      path: "order-history/:orderId",
      component: _70eaa73e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nl_et"
    }]
  }, {
    path: "/nl_et/Faq",
    component: _7e477c33,
    name: "Faq___nl_et"
  }, {
    path: "/nl_et/ForgotPassword",
    component: _f12a585e,
    name: "ForgotPassword___nl_et"
  }, {
    path: "/nl_et/Home",
    component: _09d6d1dc,
    name: "Home___nl_et"
  }, {
    path: "/nl_et/Page",
    component: _4b49acc2,
    name: "Page___nl_et"
  }, {
    path: "/nl_et/Register",
    component: _30ae4956,
    name: "Register___nl_et"
  }, {
    path: "/nl_et/Storyblok",
    component: _20ee94f8,
    name: "Storyblok___nl_et"
  }, {
    path: "/nl_et/thank-you",
    component: _be8e9d8e,
    name: "thank-you___nl_et"
  }, {
    path: "/us_et/AboutUs",
    component: _27adb9b0,
    name: "AboutUs___us_et"
  }, {
    path: "/us_et/Business",
    component: _6e5f03f3,
    name: "Business___us_et"
  }, {
    path: "/us_et/cart",
    component: _8a346bd8,
    name: "cart___us_et"
  }, {
    path: "/us_et/checkout",
    component: _014b8b3a,
    name: "checkout___us_et",
    children: [{
      path: "billing",
      component: _1ebd8af4,
      name: "billing___us_et"
    }, {
      path: "payment",
      component: _e15ee6de,
      name: "payment___us_et"
    }, {
      path: "shipping",
      component: _63404cb3,
      name: "shipping___us_et"
    }, {
      path: "thank-you",
      component: _4cce185a,
      name: "thank-you___us_et"
    }, {
      path: "user-account",
      component: _23a4c6ed,
      name: "user-account___us_et"
    }]
  }, {
    path: "/us_et/Cms",
    component: _17a9be34,
    name: "Cms___us_et"
  }, {
    path: "/us_et/Contact",
    component: _a7399486,
    name: "Contact___us_et"
  }, {
    path: "/us_et/customer",
    component: _9ab36232,
    meta: {"titleLabel":"My Account"},
    name: "customer___us_et",
    children: [{
      path: "addresses-details",
      component: _1c6e1d56,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___us_et"
    }, {
      path: "fast-order",
      component: _5d03a850,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___us_et"
    }, {
      path: "my-downloads",
      component: _17fdde25,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___us_et"
    }, {
      path: "my-newsletter",
      component: _76e866ef,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___us_et"
    }, {
      path: "my-profile",
      component: _469ff2de,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___us_et"
    }, {
      path: "my-reviews",
      component: _5dbfdc56,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___us_et"
    }, {
      path: "my-wishlist",
      component: _5217217b,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___us_et"
    }, {
      path: "order-history",
      component: _6afee6d6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___us_et"
    }, {
      path: "addresses-details/create",
      component: _794219e1,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___us_et"
    }, {
      path: "/us_et/reset-password",
      component: _2b5a4d90,
      alias: "/customer/account/createPassword",
      name: "reset-password___us_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _81f399ce,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___us_et"
    }, {
      path: "order-history/:orderId",
      component: _70eaa73e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___us_et"
    }]
  }, {
    path: "/us_et/Faq",
    component: _7e477c33,
    name: "Faq___us_et"
  }, {
    path: "/us_et/ForgotPassword",
    component: _f12a585e,
    name: "ForgotPassword___us_et"
  }, {
    path: "/us_et/Home",
    component: _09d6d1dc,
    name: "Home___us_et"
  }, {
    path: "/us_et/Page",
    component: _4b49acc2,
    name: "Page___us_et"
  }, {
    path: "/us_et/Register",
    component: _30ae4956,
    name: "Register___us_et"
  }, {
    path: "/us_et/Storyblok",
    component: _20ee94f8,
    name: "Storyblok___us_et"
  }, {
    path: "/us_et/thank-you",
    component: _be8e9d8e,
    name: "thank-you___us_et"
  }, {
    path: "/de_et/:slug+",
    component: _4b49acc2,
    name: "page___de_et"
  }, {
    path: "/fr_et/:slug+",
    component: _4b49acc2,
    name: "page___fr_et"
  }, {
    path: "/nl_et/:slug+",
    component: _4b49acc2,
    name: "page___nl_et"
  }, {
    path: "/us_et/:slug+",
    component: _4b49acc2,
    name: "page___us_et"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
