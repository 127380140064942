import { render, staticRenderFns } from "./FooterColumn.vue?vue&type=template&id=628fb866&scoped=true&"
import script from "./FooterColumn.vue?vue&type=script&lang=js&"
export * from "./FooterColumn.vue?vue&type=script&lang=js&"
import style0 from "./FooterColumn.vue?vue&type=style&index=0&id=628fb866&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628fb866",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SimpleLink: require('/home/fbtest-eratua/eratua-fe/vsf/components/storyblok/SimpleLink.vue').default})
